import "./notificationPopup.scss"
import { ButtonWhite } from "../buttonWhite/buttonWhite"

export const NotificationPopup = (props) => {
  const { show, text, close } = props
  if (!show) {
    return null
  }
  return (
    <div className="notification-wrapper">
      <div className="notification">
        <div className="text">{text}</div>
        <div className="btn-close">
          <ButtonWhite text="סגור" clickHandler={() => close()} />
        </div>
      </div>
    </div>
  )
}
