import "./yesNoPopup.scss"
import { ButtonWhite } from "../buttonWhite/buttonWhite"

export const YesNoPopup = (props) => {
  const { show, text, close, confirm } = props
  if (!show) {
    return null
  }
  return (
    <div className="yesNoPopup-wrapper">
      <div className="yesNoPopup">
        <p>{text}</p>
        <div className="btn-holder">
          <ButtonWhite
            text="לא"
            extraclass="btn-yesno"
            clickHandler={() => close()}
          />
          <ButtonWhite
            text="כן"
            extraclass="btn-yesno"
            clickHandler={() => confirm()}
          />
        </div>
      </div>
    </div>
  )
}
