import { createSlice } from "@reduxjs/toolkit"

export const driverMapSlice = createSlice({
  name: "driverMap",
  initialState: {
    showDriverMap: false,
    currentView: { latitude: 0, long: 0 },
    isMapMinimized: false,
    isMapHeightMinimized: false,
    currentLat: 0,
    currentLong: 0,
  },
  reducers: {
    setShowDriverMap: (state, action) => {
      state.showDriverMap = action.payload
    },
    setMapView: (state, action) => {
      state.currentView = action.payload
    },
    setMinimizeMap: (state, action) => {
      if (!state.isMapMinimized) {
        state.isMapMinimized = true
      } else {
        state.isMapMinimized = false
      }
    },
    setHightMinimizeMap: (state, action) => {
      if (!state.isMapHeightMinimized) {
        state.isMapHeightMinimized = true
      } else {
        state.isMapHeightMinimized = false
      }
    },
    setCurrentLocation: (state, action) => {
      state.currentLat = action.payload.latitude
      state.currentLong = action.payload.longitude
    },
  },
})

export const {
  setShowDriverMap,
  setMapView,
  setMinimizeMap,
  setHightMinimizeMap,
  setCurrentLocation,
} = driverMapSlice.actions

export default driverMapSlice.reducer
