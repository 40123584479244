import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (userInput, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}/Login`,
        userInput,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getUserData = createAsyncThunk(
  "login/getUserData",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/User/${token}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const closeExpiredDriverSession = createAsyncThunk(
  "login/closeExpiredDriverSession",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_KEY}/DriverRouteSession/closeExpiredDriverSession`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    loginSuccess: false,
    loginFail: false,
    userloginData: null,
    userData: null,
    getUSerSuccess: false,
    getUSerFail: false,
    userToken: null,
    isExpiredSessionClosed: false,
  },
  reducers: {
    clearUserData: (state, { payload }) => {
      state.userData = null
      state.loginSuccess = false
      state.loginFail = false
      state.userloginData = null
      state.getUSerSuccess = false
      state.getUSerFail = false
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.loginSuccess = false
        state.loginFail = false
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loginSuccess = true
        state.loginFail = false
        state.userloginData = payload
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false
        state.loginFail = true
        state.loginSuccess = false
      })

      .addCase(getUserData.pending, (state) => {
        state.loading = true
        state.getUSerSuccess = false
        state.getUSerFail = false
      })

      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state.loading = false
        state.getUSerSuccess = true
        state.getUSerFail = false
        state.userData = payload
      })

      .addCase(getUserData.rejected, (state, { payload }) => {
        state.loading = false
        state.getUSerFail = true
        state.getUSerSuccess = false
      })

      .addCase(closeExpiredDriverSession.fulfilled, (state, { payload }) => {
        state.isExpiredSessionClosed = true
      })

      .addCase(closeExpiredDriverSession.rejected, (state, { payload }) => {
        state.isExpiredSessionClosed = false
      })
  },
})

export const { clearUserData } = loginSlice.actions

export default loginSlice.reducer
