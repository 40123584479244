import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonWhite } from "../../../common/buttonWhite/buttonWhite"
import { taskAccomplishedReport } from "../stationTaskSlice"
import "./completeTask.scss"

export const CompleteTask = (props) => {
  const { show, close, selectedTaskId } = props
  const [notes, setNotes] = useState("")
  const [error, setError] = useState("")
  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const { userloginData } = useSelector((state) => state.login)
  const { loading } = useSelector((state) => state.stationTask)
  const dispatch = useDispatch()

  if (!show) {
    return null
  }

  const handleTextChange = (event) => {
    setNotes(event.target.value)
  }

  const onCloseClicked = () => {
    setNotes("")
    setError("")
    setSelectedFile(null)
    close()
  }

  const confirmTask = () => {
    if (notes.length < 4) {
      setError("נא להזין הערה")
      return
    }
    dispatch(
      taskAccomplishedReport({
        token: userloginData.token,
        taskId: selectedTaskId,
        notes: notes,
        imageFile: selectedFile,
      })
    )
    setNotes("")
    setError("")
    setSelectedFile(null)
  }

  const handleFileSelect = (evt) => {
    const file = evt.target.files[0]
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file)
    } else {
      alert("Please select a valid image file.")
    }
  }

  return (
    <div className="completeTask-wrapper">
      <div className="completeTask">
        <p>דיווח על ביצוע משימה</p>
        <div className="text-area-wrapper">
          <textarea
            value={notes}
            onChange={handleTextChange}
            rows={6}
            placeholder="הוסף הערה"
          />
        </div>
        {selectedFile?.name}
        {error && <div className="error">{error}</div>}
        <div className="btn-holder">
          <ButtonWhite
            text="ביטול"
            extraclass={`btn-yesno ${loading ? "disabled" : ""}`}
            clickHandler={() => onCloseClicked()}
          />
          <div className="file-input-container">
            <label
              className={`custom-file-button ${loading ? "disabled" : ""}`}
            >
              העלה תמונה
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                accept="image/*"
                onChange={(evt) => handleFileSelect(evt)}
              />
            </label>
          </div>
          <ButtonWhite
            text="אישור"
            extraclass={`btn-yesno ${loading ? "disabled" : ""}`}
            clickHandler={() => confirmTask()}
          />
        </div>
      </div>
    </div>
  )
}
