import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import { getFormatedDate } from "../../../utils/formatDate"
import { ButtonWhite } from "../../common/buttonWhite/buttonWhite"
import { CompleteTask } from "./completeTask/completeTask"
import downArrow from "../../../assets/images/down-arrow.png"
import upArrow from "../../../assets/images/up-arrow.png"
import "./stationTask.scss"

export const StationTask = (props) => {
  const { tasks } = props
  const { currentLanguage } = useSelector((state) => state.main)
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState(false)
  const [showTasks, setShowTasks] = useState(true)
  const { taskReportedSuccess, nearbyStation, stationTasks } = useSelector(
    (state) => state.stationTask
  )
  const dispatch = useDispatch()

  const doneClickHandler = (taskId) => {
    setIsShowConfirmPopup(true)
    setSelectedTaskId(taskId)
  }

  useEffect(() => {
    if (taskReportedSuccess) {
      setIsShowConfirmPopup(false)
    }
  }, [taskReportedSuccess])

  return (
    <div className="station-task">
      <div className="btn-tasks" onClick={() => setShowTasks(!showTasks)}>
        {showTasks ? (
          <div>
            <img src={upArrow} alt="up arrow"></img> סגור משימות
          </div>
        ) : (
          <div>
            <img src={downArrow} alt="down arrow"></img> פתח משימות
          </div>
        )}
      </div>
      <div className={`tasks-wrapper ${showTasks ? "" : "collapsed"}`}>
        <div className="border">
          {tasks?.map((task, index) => {
            return (
              <div className="task">
                <div> משימה: {task.taskNumber}</div>
                <div>
                  {currentLanguage == "He"
                    ? task.taskDetails
                    : task.taskDetails_en}
                </div>
                <div>{getFormatedDate(task.createdDate)}</div>
                <div>
                  <ButtonWhite
                    text="בוצע"
                    extraclass="btn-task-done"
                    clickHandler={() => doneClickHandler(task.id)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <CompleteTask
        selectedTaskId={selectedTaskId}
        show={isShowConfirmPopup}
        close={() => setIsShowConfirmPopup(false)}
      ></CompleteTask>

      <div></div>
    </div>
  )
}
