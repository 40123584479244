import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getRoutes = createAsyncThunk(
  "routes/getRoutes",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Route`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const endDriverRouteSessions = createAsyncThunk(
  "routes/endDriverRouteSessions",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_KEY}/DriverRouteSession/endDriverRouteSessions/${params.routeId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const driverRoutesSlice = createSlice({
  name: "routes",
  initialState: {
    routes: [],
    getRoutesSuccess: false,
    getRoutesFail: false,
    selectedRouteIndex: null,
    selectedRouteId: null,
    showRoutes: true,
    driverCurrentRoute: null,
    routeName: null,
    endDriverSessionSuccess: false,
    endDriverSessionFail: false,
  },
  reducers: {
    setSelectedRoute: (state, action) => {
      state.selectedRouteIndex = action.payload.routeIndex
      state.selectedRouteId = action.payload.routeId
      state.routeName = action.payload.routeName
    },
    setShowRoutes: (state, action) => {
      state.showRoutes = action.payload
    },
    setDriverRoute: (state, action) => {
      state.driverCurrentRoute = state.selectedRouteId
    },
    clearSelectedRoute: (state) => {
      state.selectedRouteIndex = null
      state.selectedRouteId = null
      state.showRoutes = true
      state.driverCurrentRoute = null
    },
    setOnTopSelectedRoute: (state, action) => {
      let element = state.routes[state.selectedRouteIndex]
      state.routes.splice(state.selectedRouteIndex, 1)
      state.routes.splice(0, 0, element)
      state.selectedRouteIndex = 0
    },
    clearEndDriverSessions: (state) => {
      state.endDriverSessionSuccess = false
      state.endDriverSessionFail = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoutes.pending, (state) => {
        state.getRoutesSuccess = false
        state.getRoutesFail = false
      })
      .addCase(getRoutes.fulfilled, (state, { payload }) => {
        state.getRoutesSuccess = true
        state.getRoutesFail = false
        state.routes = payload
      })
      .addCase(getRoutes.rejected, (state, { payload }) => {
        state.getRoutesSuccess = false
      })

      .addCase(endDriverRouteSessions.pending, (state) => {
        state.endDriverSessionSuccess = false
        state.endDriverSessionFail = false
      })
      .addCase(endDriverRouteSessions.fulfilled, (state, { payload }) => {
        state.endDriverSessionSuccess = true
        state.endDriverSessionFail = false
      })
      .addCase(endDriverRouteSessions.rejected, (state, { payload }) => {
        state.endDriverSessionSuccess = false
        state.endDriverSessionFail = true
      })
  },
})

export const {
  setSelectedRoute,
  setShowRoutes,
  setDriverRoute,
  clearSelectedRoute,
  setOnTopSelectedRoute,
  clearEndDriverSessions,
} = driverRoutesSlice.actions

export default driverRoutesSlice.reducer
