import "./buttonWaze.scss"
import iconWazeOrange from "../../../assets/images/iconWazeOrange.png"

export const ButtonWaze = (props) => {
  const { latitude, longitude } = props

  const handleClick = (event) => {
    event.stopPropagation()

    window.open(
      "waze://?ll=" + latitude + "," + longitude + "&navigate=yes&z=10"
    )
  }

  return (
    <div className="button-waze" onClick={handleClick}>
      <img alt="icon waze" className="" src={iconWazeOrange} />
    </div>
  )
}
