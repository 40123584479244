import "./singleRoute.scss"
import { ButtonWhite } from "../../common/buttonWhite/buttonWhite"
import btnArrow from "../../../assets/images/arrow-left.svg"
import lockerOrange from "../../../assets/images/lockerOrange.svg"
import lockerGrey from "../../../assets/images/lockerGrey.svg"
import markerStar from "../../../assets/images/markerStar.png"
import markerOrange from "../../../assets/images/markerOrange.svg"
export const SingleRoute = (props) => {
  const {
    route,
    index,
    selectedRouteIndex,
    stationsWithoutOrdersCount,
    stationsWithOrdersCount,
    loading,
    currentRouteIdFromCache,
  } = props
  const { label, value } = route
  const unselected = (
    <section key={index} className="route">
      <div className="label-route">{label}</div>
      <div className="star-icon-wrapper">
        <img alt="icon map" src={markerOrange} className="star-icon" />
        <div className="route-container">
          <ButtonWhite
            extraclass="btn-select-route"
            text={
              currentRouteIdFromCache == props.route.value
                ? "המשך במסלול"
                : "בחירת מסלול"
            }
            clickHandler={() => props.onStationClicked({ index, value, label })}
          />
        </div>
      </div>
    </section>
  )

  const selected = (
    <section className="route" key={index}>
      <div className="label-route">{label}</div>
      <div className="star-icon-wrapper">
        <img alt="star" src={markerStar} className="star-icon" />
        <div className="route-container selected">
          <div className="station">
            <div className="count-window">
              <span className="orange">
                {stationsWithOrdersCount ? stationsWithOrdersCount : "0"}
              </span>
              <img className="locker" src={lockerOrange} />
            </div>
            <span className="window-text">עמדות פעילות</span>
          </div>
          <div className="station">
            <div className="count-window">
              <span>
                {stationsWithoutOrdersCount ? stationsWithoutOrdersCount : "0"}
              </span>
              <img className="locker" src={lockerGrey} />
            </div>
            <span className="window-text">עמדות ללא איסוף</span>
          </div>
          <img alt="arrow" src={btnArrow} />
        </div>
        {/* {loading ? null : (
          <div>
            <div className="triangle">
              <div className="inner-triangle"></div>
            </div>
          </div>
        )} */}
      </div>
    </section>
  )

  return <>{index == selectedRouteIndex ? selected : unselected}</>
}
