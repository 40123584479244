import "./buttonWhite.scss"

export const ButtonWhite = (props) => {
  const { text, clickHandler, extraclass } = props

  const handleClick = (event) => {
    event.stopPropagation()
    if (clickHandler) {
      clickHandler()
    }
  }

  return (
    <button
      className={`button-white ${extraclass}`}
      {...props}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}
