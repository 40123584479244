import { Menu } from "../menu/menu.js"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import phone from "../../../assets/images/phone.png"
import { setShowRoutes } from "../../../features/routes/routesSlice"
import { setShowDriverMap } from "../../driverMap/driverMapSlice"
import {
  setTestLong,
  setTestLat,
} from "../../../pages/mainPage/mainPageSlice.js"
import { ButtonWhite } from "../../common/buttonWhite/buttonWhite"
import LanguageSwitcher from "../languageSwitcher/languageSwitcher"
import "./header.scss"
import { setShowStations } from "../../stations/stationsSlice.js"

export const Header = (props) => {
  const { showDriverMap } = useSelector((state) => state.driverMap)
  const { routeName } = useSelector((state) => state.routes)
  const dispatch = useDispatch()
  const [isWindows, setIsWindows] = useState(false)

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
    } else {
      setIsWindows(true)
    }
  }, [])

  const goBackToRoutes = () => {
    dispatch(setShowDriverMap(false))
    dispatch(setShowRoutes(true))
    dispatch(setShowStations(false))
  }

  return (
    <div className="header-wrapper">
      <div className="header">
        <LanguageSwitcher />
        {showDriverMap ? (
          <div className="btn-back">
            <ButtonWhite
              text="לבחירת מסלול"
              clickHandler={() => goBackToRoutes()}
            />
            <div className="route-name">{routeName}</div>
          </div>
        ) : null}
      </div>

      {/* {isWindows ? (
        <div className="test-coordinates">
          <input
            placeholder="longitude"
            onChange={(e) => dispatch(setTestLong(e.target.value))}
          ></input>
          <input
            placeholder="latitude"
            onChange={(e) => dispatch(setTestLat(e.target.value))}
          ></input>
        </div>
      ) : null} */}
      <div className="call">
        <img src={phone} alt="phone icon"></img>5099*
      </div>
      <Menu />
    </div>
  )
}
