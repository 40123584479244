import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setLanguage } from "../../../pages/mainPage/mainPageSlice.js"
import "./languageSwitcher.scss"

const LanguageSwitcher = () => {
  const { currentLanguage } = useSelector((state) => state.main)
  const dispatch = useDispatch()

  const handleLanguageToggle = () => {
    dispatch(setLanguage(currentLanguage === "En" ? "He" : "En"))
  }

  return (
    <div className="lang-switcher">
      <button className="button-white" onClick={handleLanguageToggle}>
        {currentLanguage}
      </button>
    </div>
  )
}

export default LanguageSwitcher
