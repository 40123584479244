import React, { useState } from "react"
import icon_burger from "../../../assets/images/icon_burger.png"
import icon_burger_close from "../../../assets/images/icon_burger_close.png"
import logout from "../../../assets/images/logout.png"
import { clearUserData } from "../../../pages/loginPage/loginSlice.js"
import { setShowScanPage } from "../../barcodeScanner/barcodeScannerSlice.js"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Popup } from "../popup/popup"
import "./menu.scss"

export const Menu = (props) => {
  const [isCollapsed, setCollapse] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lastStationsRequestParams } = useSelector((state) => state.stations)
  const { userloginData } = useSelector((state) => state.login)
  const [showPopup, setshowPopup] = useState(false)
  const [requestParams, setRequestParams] = useState(false)
  const userLogout = () => {
    dispatch(clearUserData())
    navigate("/")
    window.location.reload(true)
  }

  const displayStationRequestParams = () => {
    const params = (
      <div className="request-data">
        <div>routeId: {lastStationsRequestParams.routeId}</div>
        <div>latitude: {lastStationsRequestParams.latitude}</div>
        <div>longitude: {lastStationsRequestParams.longitude}</div>
        <div>time of request: {lastStationsRequestParams.timeOfRequest}</div>
        <div>
          isSetDriverRouteToCache:{" "}
          {lastStationsRequestParams.isSetDriverRouteToCache == true
            ? "true"
            : "false"}
        </div>
        <div>
          useStationRouteCache:{" "}
          {lastStationsRequestParams.useStationRouteCache == true
            ? "true"
            : "false"}
        </div>
      </div>
    )
    setRequestParams(params)
    setshowPopup(!showPopup)
  }

  const onScanClicked = () => {
    dispatch(setShowScanPage(true))
    setCollapse(false)
  }

  return (
    <div className="menu">
      <img
        alt="menu"
        className=""
        src={isCollapsed ? icon_burger_close : icon_burger}
        onClick={() => {
          setCollapse(!isCollapsed)
        }}
      />
      {isCollapsed ? (
        <div className="collapse">
          <div className="menu-drop">
            <div className="version">
              version: {process.env.REACT_APP_VERSION}
            </div>
            <div
              className="logout"
              onClick={() => {
                userLogout()
              }}
            >
              <span>יציאה</span>
              <img alt="icon-logout" className="" src={logout} />
            </div>
            <div
              className="request-data"
              onClick={() => {
                onScanClicked()
              }}
            >
              <div>
                <span>סריקת הזמנות</span>
              </div>
            </div>
            <div
              className="request-data"
              onClick={() => {
                displayStationRequestParams()
              }}
            >
              <div>
                <span>הצג נתונים</span>
              </div>
            </div>

            <Popup
              show={showPopup}
              text={requestParams}
              close={() => setshowPopup(false)}
            ></Popup>
          </div>
          <div className="menu-overlay"></div>
        </div>
      ) : null}
    </div>
  )
}
