import "./popup.scss"

export const Popup = (props) => {
  const { show, text, close } = props
  if (!show) {
    return null
  }
  return (
    <div className="popup-wrapper">
      <div className="popup">
        <p>{text}</p>
        <div className="btn-close" onClick={() => close()}>
          סגור
        </div>
      </div>
    </div>
  )
}
