import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import markerBlack from "../../assets/images/markerBlack.svg"
import btnMinimizeMap from "../../assets/images/btn_minimize_map.png"
import btnMaximizeMap from "../../assets/images/btn_maximize_map.png"
import btnMapCenter from "../../assets/images/btn_map_center.png"
import iconCar from "../../assets/images/iconCar.png"
import { FullscreenControl } from "react-leaflet-fullscreen"
import { ButtonWaze } from "../common/buttonWaze/buttonWaze.js"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet"
import L from "leaflet"
import { setMinimizeMap, setHightMinimizeMap } from "./driverMapSlice"
import "./driverMap.scss"
import "leaflet/dist/leaflet.css"
import "leaflet.fullscreen/Control.FullScreen.css"

export function DriverMap() {
  const dispatch = useDispatch()
  const { stationsList } = useSelector((state) => state.stations)
  const {
    currentView,
    isMapMinimized,
    isMapHeightMinimized,
    currentLat,
    currentLong,
  } = useSelector((state) => state.driverMap)

  const mapRef = useRef(0)

  useEffect(() => {
    const { latitude, longitude } = currentView
    if (latitude && longitude) {
      if (mapRef.current != null) {
        mapRef.current.flyTo([latitude, longitude], 14)
      }
    }
  }, [currentView])

  const markerIcon = new L.Icon({
    iconUrl: markerBlack,
    iconSize: [35, 45],
  })

  const carIcon = new L.Icon({
    iconUrl: iconCar,
    iconSize: [35, 45],
  })

  const getStationsLocation = () => {
    return stationsList?.map((station) => {
      if (station.ordersTotal !== 0 && station.routePlanningStatus != 2) {
        return (
          <Marker
            position={[station.latitude, station.longitude]}
            icon={markerIcon}
          >
            <Popup>
              <div>{station.name}</div>
              <div>{station.address + " - " + station.stationNumber}</div>
              <div>
                <ButtonWaze
                  latitude={station.latitude}
                  longitude={station.longitude}
                />
              </div>
            </Popup>
          </Marker>
        )
      }
    })
  }

  const setCurrentPositionMarker = () => {
    if (!currentLat || !currentLong) {
      return null
    }
    return <Marker position={[currentLat, currentLong]} icon={carIcon}></Marker>
  }

  return (
    <div className="driver-map-container">
      <div
        className={`drivermap-wrapper  ${
          isMapMinimized ? "miniScreen" : "maxScreen"
        } ${isMapHeightMinimized ? "minHeight" : "maxHeight"}`}
      >
        <div className="btn-minimize">
          <img
            alt="minimize map button"
            src={isMapMinimized ? btnMaximizeMap : btnMinimizeMap}
            onClick={() => dispatch(setMinimizeMap())}
          />
        </div>
        <div className="btn-horizonal-minimize">
          {/* <img
            alt="minimize map button"
            src={isMapMinimized ? btnMaximizeMap : btnMinimizeMap}
            onClick={() => dispatch(setMinimizeMap())}
          /> */}
          <button onClick={() => dispatch(setHightMinimizeMap())}>
            {isMapHeightMinimized ? "פתח מפה" : "סגור מפה"}
          </button>
        </div>
        <MapContainer
          className="map"
          center={[31.411725, 35.0818155]}
          zoom={8}
          zoomControl={false}
          attributionControl={false}
          ref={mapRef}
        >
          <FullscreenControl position="topleft" />
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <div className="btn-center">
            <img
              alt="center map button"
              src={btnMapCenter}
              onClick={() =>
                mapRef.current.flyTo([currentLat, currentLong], 16)
              }
            />
          </div>
          {setCurrentPositionMarker()}
          {getStationsLocation()}
        </MapContainer>
      </div>
    </div>
  )
}
