import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import axios from "axios"

export const getStationTasksByStation = createAsyncThunk(
  "stationTask/getStationTasksByStation",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/StationTask/GetStationTasksByStation/${params.stationId}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const taskAccomplishedReport = createAsyncThunk(
  "stationTask/taskAccomplishedReport",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append("note", params.notes)
      formData.append("imageFile", params.imageFile)

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_KEY}/StationTask/taskAccomplishedReport/${params.taskId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const stationTaskSlice = createSlice({
  name: "stationTask",
  initialState: {
    loading: false,
    stationTasks: [],
    taskReportedSuccess: false,
    taskReportedFail: false,
  },
  reducers: {
    clearStationTaskData: (state, { payload }) => {
      state.stationTasks = []
      state.taskReportedSuccess = false
      state.taskReportedFail = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationTasksByStation.pending, (state) => {
        state.loading = true
      })
      .addCase(getStationTasksByStation.fulfilled, (state, { payload }) => {
        state.loading = false
        state.stationTasks = payload
      })
      .addCase(getStationTasksByStation.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(taskAccomplishedReport.pending, (state) => {
        state.loading = true
        state.taskReportedSuccess = false
        state.taskReportedFail = false
      })
      .addCase(taskAccomplishedReport.fulfilled, (state, { payload }) => {
        state.loading = false
        state.taskReportedSuccess = true
        state.taskReportedFail = false
      })
      .addCase(taskAccomplishedReport.rejected, (state, { payload }) => {
        state.loading = false
        state.taskReportedFail = true
        state.taskReportedSuccess = false
      })
  },
})

export const { clearStationTaskData } = stationTaskSlice.actions
export default stationTaskSlice.reducer
