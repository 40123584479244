import React, { useState, useEffect } from "react"
import box from "../../../assets/images/box.svg"
import hanger from "../../../assets/images/icon_hanger.svg"
import iconPost from "../../../assets/images/logo_post.png"
import iconGeffen from "../../../assets/images/logo_geffen.png"
import iconDone from "../../../assets/images/logo_done.png"
import laundryBag from "../../../assets/images/icon_laundry_bag.svg"
import iconFolded from "../../../assets/images/icon_folded.png"

import "./singleOrder.scss"

export const SingleOrder = (props) => {
  const [sackCount, setSackCount] = useState(0)
  const [hangingCount, setHangingCount] = useState(0)
  const [folded, setFoldedCount] = useState(0)
  const [showOrderLogo, setShowOrderLogo] = useState(false)

  const {
    orderStatus,
    orderType,
    orderDate,
    orderNumber,
    customer,
    bagsQuantity,
    orderItems,
    accountType,
  } = props.orderData || {}
  const { firstName, lastName, mobilePhone } = customer || {}

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      calculateItemsByProccess()
    }
  }, [orderItems])

  useEffect(() => {
    if (props.orderTypes?.length > 0 && props.accountTypes?.length > 0) {
      setShowOrderLogo(true)
    }
  }, [props.orderTypes, props.accountTypes])

  const calculateItemsByProccess = () => {
    let countHanging = 0
    let countSack = 0
    let countFolded = 0
    for (let item of orderItems) {
      if (
        item["processName"] == "ironing" ||
        item["processName"] == "laundry ironing" ||
        item["processName"] == "dry cleaning"
      ) {
        countHanging += 1
      }
      if (item["processName"] == "laundry") {
        countSack = bagsQuantity
      }
      if (
        item["processName"] == "ironing and package" ||
        item["processName"] == "laundry ironing package"
      ) {
        countFolded += 1
      }
    }
    setHangingCount(countHanging)
    setSackCount(countSack)
    setFoldedCount(countFolded)
  }

  const setTitleByOrderType = () => {
    const ordertype = props.orderTypes.find((item) => item.value == orderType)
    const account = props.accountTypes.find((item) => item.value == accountType)
    switch (ordertype.value) {
      case "1":
      case "5":
        return (
          <img className="icon-logo" alt="order type icon" src={iconDone} />
        )
      case "2":
        return (
          <img className="icon-logo" alt="order type icon" src={iconPost} />
        )
      case "18":
        return (
          <img className="icon-logo" alt="order type icon" src={iconGeffen} />
        )
      case "8":
      case "31":
        return <div>{account?.label}</div>
      default:
        return <div>{ordertype?.label}</div>
    }
  }

  return (
    <div
      className={`single-order-wrapper ${
        orderStatus === 3 ? "return" : "pickup"
      }`}
    >
      <div className="order-number">{orderNumber}</div>
      <div className="customer">
        <div>{firstName + " " + lastName}</div>
        <div>{mobilePhone}</div>
      </div>
      <div className="logo-wrapper">
        {showOrderLogo ? setTitleByOrderType() : null}
      </div>
      <div className="separator-wrapper">
        <div className="separator"></div>
      </div>

      <div className="details">
        {orderType !== 1 ? (
          <div className="">
            <img className="icon-laundry" alt="icon delivery" src={box} />
          </div>
        ) : null}

        {orderType == 1 && orderStatus == 0 ? (
          <div>
            <img
              className="icon-laundry"
              alt="icon delivery"
              src={laundryBag}
            />
          </div>
        ) : null}

        {orderType == 1 && orderStatus == 3 ? (
          <div className="return-orders">
            {sackCount ? (
              <div className="sack">
                <img
                  className="icon-laundry"
                  alt="icon laundry"
                  src={laundryBag}
                />
                <div className="bags-quantity">
                  <span className="sign-multi">x</span>
                  <span className="items-count">{bagsQuantity}</span>
                </div>
              </div>
            ) : null}
            {hangingCount > 0 ? (
              <div className="hanging">
                {sackCount ? <span className="sign-multi"> +</span> : null}
                <img className="icon-hanger" alt="icon hanger" src={hanger} />
              </div>
            ) : null}

            {folded ? (
              <div className="folded">
                {hangingCount || sackCount ? (
                  <span className="sign-multi">+</span>
                ) : null}
                <img
                  className="icon-folded"
                  alt="icon hanger"
                  src={iconFolded}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
