export const getFormatedDate = (dateStr) => {
  if (dateStr == null) return ""
  const dateObj = new Date(dateStr)
  dateObj.setTime(dateObj.getTime() + 2 * 60 * 60 * 1000)

  const result = dateObj
    .toLocaleString("he-IL", { timeZone: "Israel" })
    .replace(/\,/g, ",")
    .split(":", 2)
    .join(":")
  return result
}

export const getCurrentTimeUnixFormat = () => {
  const currentTime = new Date()
  const utcTimeUnixFormat = Math.floor(currentTime.getTime() / 1000)
  return utcTimeUnixFormat
}
