import React, { useEffect, useState } from "react"
import "./singleStation.scss"
import dropOff from "../../../assets/images/drop_off.svg"
import pickup from "../../../assets/images/pickup.svg"
import box from "../../../assets/images/box.svg"
import shirt from "../../../assets/images/shirt.svg"
import markerBlack from "../../../assets/images/markerBlack.svg"
import markerDisabled from "../../../assets/images/markerDisabled.svg"
import { ButtonWaze } from "../../common/buttonWaze/buttonWaze.js"
import { useSelector } from "react-redux"
import todo from "../../../assets/images/todo.png"
import dotGreen from "../../../assets/images/dot_green.png"
import dotRed from "../../../assets/images/dot_red.png"
import { ButtonWhite } from "../../../features/common/buttonWhite/buttonWhite"
import { YesNoPopup } from "../../common/yesNoPopup/yesNoPopup"

export const SingleStation = (props) => {
  const [isShowHoldStationPopup, setIsShoHoldStationPopup] = useState(false)
  const [isEnouchFreeLockers, setIsEnouchFreeLockers] = useState(false)
  const { stationData, index, showMapIcons, currentLanguage } = props
  const {
    id,
    pickupLaundryCount,
    pickupDeliveryCount,
    returnLaundryCount,
    returnDeliveryCount,
    cityName,
    cityName_en,
    address,
    address_en,
    name_en,
    name,
    ordersTotal,
    latitude,
    longitude,
    stationNumber,
    routePlanningStatus,
    isAlive,
    stationTasksCount,
    stationTasksOptionalCount,
    emptyLockersNum,
    emptyLaundryLockersNum,
    emptyDeliveryLockersNum,
  } = stationData
  const isShowTask = stationTasksCount > 0 || stationTasksOptionalCount > 0
  const stationCoordinates = {
    latitude: latitude,
    longitude: longitude,
  }
  let isStationDisabled =
    (ordersTotal === 0 && stationTasksCount === 0) || routePlanningStatus == 2
  let isHightlite =
    !isStationDisabled &&
    (stationTasksCount > 0 || stationTasksOptionalCount > 0)

  useEffect(() => {
    if (emptyLockersNum) {
      const isEnouch = checkIfEnouchEmptyLockers()
      setIsEnouchFreeLockers(isEnouch)
    }
  }, [emptyLockersNum])

  const withoutOrders = (
    <div className="no-orders">
      <div className="separator"></div>
      <div className="text">
        <div>אין צורך לעבור בעמדה</div>
      </div>
    </div>
  )

  const withOrders = (
    <div className="order-section">
      <div className="separator"></div>
      <div className="driver-action">
        <div
          className={`action-name ${
            routePlanningStatus == 2 ? "disabled-arrow" : null
          }`}
        >
          <img alt="arrow up" src={pickup} />
          <span>איסוף</span>
        </div>
        <div className="count">
          <span>{pickupDeliveryCount}</span>
          <img
            className={`img-box ${
              routePlanningStatus == 2 ? "disabled-icon" : null
            }`}
            alt="icon box"
            src={box}
          />
          <span>{pickupLaundryCount}</span>
          <img
            className={` ${routePlanningStatus == 2 ? "disabled-icon" : null}`}
            alt="icon shirt"
            src={shirt}
          />
        </div>
      </div>
      <div className="separator"></div>
      <div className="driver-action">
        <div
          className={`action-name ${
            routePlanningStatus == 2 ? "disabled-arrow" : null
          }`}
        >
          <img alt="arrow up" src={dropOff} />
          <span>הפקדה</span>
        </div>
        <div className="count">
          <span
            className={`${
              isEnouchFreeLockers ? null : !isStationDisabled && "count-red"
            }`}
          >
            {returnDeliveryCount}
          </span>
          <img
            className={`img-box ${
              routePlanningStatus == 2 ? "disabled-icon" : null
            }`}
            alt="icon box"
            src={box}
          />
          <span
            className={`${
              isEnouchFreeLockers ? null : !isStationDisabled && "count-red"
            }`}
          >
            {returnLaundryCount}
          </span>
          <img
            className={` ${routePlanningStatus == 2 ? "disabled-icon" : null}`}
            alt="icon shirt"
            src={shirt}
          />
        </div>
        <div className="cells-count">
          תאי כביסה פנוים: {emptyLaundryLockersNum}
        </div>
        <div className="cells-count">
          תאי חבילות פנוים:{" "}
          {Math.max(0, emptyLockersNum - emptyLaundryLockersNum)}
        </div>
      </div>
    </div>
  )

  const holdStationConfirm = () => {
    props.holdStationToNextDay(id)
    setIsShoHoldStationPopup(false)
  }

  const generateStationView = () => {
    if (ordersTotal === 0 && stationTasksCount === 0) {
      return withOrders
    } else if (routePlanningStatus == 2 && ordersTotal !== 0) {
      return withOrders
    } else {
      return withOrders
    }
  }

  const checkIfEnouchEmptyLockers = () => {
    const freeCells = emptyLockersNum + pickupDeliveryCount + pickupLaundryCount
    if (freeCells > ordersTotal) {
      return true
    } else {
      return false
    }
  }

  return (
    <section key={index} className="single-station-wrapper">
      <div className="position">
        {ordersTotal === 0 && stationTasksCount === 0 ? (
          <img
            alt="icon map"
            className="position-icon point-disabled"
            src={markerDisabled}
          />
        ) : (
          <img
            alt="icon map"
            className="position-icon point-black"
            src={markerBlack}
            onClick={() => props.setStationView(stationCoordinates)}
          />
        )}
        <div className="border"></div>
      </div>
      <div
        className={`single-station ${isStationDisabled ? "disabled" : null} ${
          isHightlite ? "yellow" : null
        }`}
        onClick={() => props.showOrders(stationData)}
      >
        <div className="addres-and-navigation">
          <div className="address-wraper">
            <div className="address-section">
              {currentLanguage == "He" ? (
                <div>
                  <div>{cityName ?? ""}</div>
                  <div>{address ?? ""}</div>
                  <div>
                    <span>{name ? name + " - " : ""}</span>
                    <span> {stationNumber ?? ""}</span>
                  </div>
                </div>
              ) : (
                <div>
                  <div>{cityName_en ?? ""}</div>
                  <div>{address_en ?? ""}</div>
                  <div>
                    <span>{name_en ? name_en + " - " : ""}</span>
                    <span> {stationNumber ?? ""}</span>
                  </div>
                </div>
              )}

              {isShowTask ? (
                <img className="img-task" src={todo} alt="todo"></img>
              ) : null}
            </div>
          </div>
          <div>
            {showMapIcons &&
            (ordersTotal !== 0 || stationTasksCount !== 0) &&
            routePlanningStatus != 2 ? (
              <div className="btn-wrapper">
                <ButtonWaze latitude={latitude} longitude={longitude} />
                <ButtonWhite
                  extraclass="btn-hold-station"
                  text="העבר למחר"
                  clickHandler={() => setIsShoHoldStationPopup(true)}
                />
              </div>
            ) : null}
          </div>
          <YesNoPopup
            show={isShowHoldStationPopup}
            text={"האם אתה בטוח שברצונך להעביר את העמדה למחר?"}
            close={() => setIsShoHoldStationPopup(false)}
            confirm={() => holdStationConfirm()}
          ></YesNoPopup>
        </div>
        {generateStationView()}
        {isAlive ? <div className="green"></div> : <div className="red"></div>}
      </div>
    </section>
  )
}
