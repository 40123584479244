import spinner from "../../../assets/images/spiner.gif"
import "./Spinner.scss"

export const Spinner = () => {
  return (
    <div className="overlay">
      <img className="spinner" src={spinner} alt="spinner" />
    </div>
  )
}
